import React, { Component } from "react";
import Ink from "react-ink";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare } from "@fortawesome/free-solid-svg-icons";

class WebShare extends Component {
  state = {
    shareButton: true,
  };
  componentDidMount() {
    if (navigator.share) {
      this.setState({ shareButton: true });
    }
  }

  shareLink = (link, title) => {
    const { isCartProduct, cartItems, restaurantSlug } = this.props;
    if (navigator.share) {
      if (isCartProduct) {
        var text = "Hey! Check out these awesome products:" + "\n";
        var count = 0;
        cartItems.forEach((item, index) => {
          count++;
          text +=
            "\n" +
            count +
            ". " +
            item.name +
            " - " +
            "https://elevateyoursport.com/stores/" +
            restaurantSlug +
            "/" +
            item.id +
            "\n";
        });
        navigator
          .share({
            text: text,
          })
          .then(() => console.log("Successful share"))
          .catch((error) => console.log("Error sharing", error));
      } else {
        var text = title;
        navigator
          .share({
            url: "https://elevateyoursport.com/public/product/" + link,
            text: text,
          })
          .then(() => console.log("Successful share"))
          .catch((error) => console.log("Error sharing", error));
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.state.shareButton && (
          <button
            type="button"
            className="btn search-navs-btns nav-home-btn"
            style={{
              position: "relative",
              fontSize: "1.5rem",
              maxWidth: "40px",
              padding: "4px",
            }}
            onClick={() => this.shareLink(this.props.link, this.props.title)}
          >
            <FontAwesomeIcon icon={faShare} />
            <Ink duration="500" />
          </button>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(WebShare);
