import React, { Component } from "react";
import Ink from "react-ink";
import LightSpeed from "react-reveal/LightSpeed";
//import { WEBSITE_URL } from "../../../configs/website";
import WebShare from "../WebShare";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";

class BackWithSearch extends Component {
  state = {
    isCartProduct: false,
    cartItems: [],
  };
  static contextTypes = {
    router: () => null,
  };

  render() {
    const title = this.props.isSingleItemDescription
      ? this.props.single_item.brand
        ? this.props.single_item.brand + " - " + this.props.single_item.name
        : this.props.single_item.name
      : this.props.restaurant.name;
    const id = this.props.isSingleItemDescription
      ? this.props.single_item.id
      : "";

    let url = this.props.restaurant.slug;
    if (id) {
      url = url + "/" + id;
    }

    const cartItems =
      this.props.isCartProduct &&
      this.props.cartItems &&
      this.props.cartItems.length > 0
        ? this.props.cartItems
        : [];

    return (
      <React.Fragment>
        <div className="col-12 p-0 fixed" style={{ zIndex: "9" }}>
          <div className="block m-0">
            <div
              className={`block-content p-0 ${this.props.dark && "nav-dark"}`}
            >
              <div
                className={`input-group ${this.props.boxshadow &&
                  "search-box"}`}
              >
                {!this.props.disable_back_button && (
                  <div className="input-group-prepend">
                    {this.props.back_to_home && (
                      <button
                        type="button"
                        className="btn search-navs-btns"
                        style={{ position: "relative", border: "none" }}
                        onClick={() => {
                          setTimeout(() => {
                            this.context.router.history.push("/");
                          }, 200);
                        }}
                      >
                        <i className="si si-arrow-left" />
                        <Ink duration="500" />
                      </button>
                    )}

                    {this.props.goto_orders_page && (
                      <button
                        type="button"
                        className="btn search-navs-btns"
                        style={{ position: "relative", border: "none" }}
                        onClick={() => {
                          setTimeout(() => {
                            this.context.router.history.push("/my-orders");
                          }, 200);
                        }}
                      >
                        <i className="si si-arrow-left" />
                        <Ink duration="500" />
                      </button>
                    )}
                    {this.props.goto_accounts_page && (
                      <button
                        type="button"
                        className="btn search-navs-btns"
                        style={{ position: "relative", border: "none" }}
                        onClick={() => {
                          setTimeout(() => {
                            this.context.router.history.push("/my-account");
                          }, 200);
                        }}
                      >
                        <i className="si si-arrow-left" />
                        <Ink duration="500" />
                      </button>
                    )}
                    {!this.props.back_to_home &&
                      !this.props.goto_orders_page &&
                      !this.props.goto_accounts_page && (
                        <button
                          type="button"
                          className={`btn search-navs-btns ${this.props.dark &&
                            "nav-dark"}`}
                          style={{ position: "relative", border: "none" }}
                          onClick={() => {
                            setTimeout(() => {
                              const currentPath = window.location.pathname;
                              const storePath = `/stores/${
                                this.props.restaurant.slug
                              }`;
                              console.log(currentPath, storePath);
                              if (currentPath === storePath) {
                                this.context.router.history.push("/");
                              } else if (
                                id &&
                                currentPath === storePath + "/" + id
                              ) {
                                this.context.router.history.push(storePath);
                              } else if (window.history.length > 1) {
                                this.context.router.history.goBack();
                              }
                            }, 200);
                          }}
                        >
                          <i className="si si-arrow-left" />
                          <Ink duration="500" />
                        </button>
                      )}
                  </div>
                )}
                <p
                  className={`form-control search-input d-flex align-items-center ${this
                    .props.dark && "nav-dark"}`}
                  style={{ border: "none" }}
                >
                  {this.props.logo && (
                    <img
                      src="/assets/img/logos/logo.png"
                      alt={localStorage.getItem("storeName")}
                      width="120"
                    />
                  )}
                  {this.props.has_title ? (
                    <React.Fragment>
                      {this.props.from_checkout ? (
                        <span className="nav-page-title">
                          {localStorage.getItem("cartToPayText")}{" "}
                          <span
                            style={{
                              color: localStorage.getItem("storeColor"),
                            }}
                          >
                            {localStorage.getItem("currencySymbolAlign") ===
                              "left" && localStorage.getItem("currencyFormat")}
                            {this.props.title}
                            {localStorage.getItem("currencySymbolAlign") ===
                              "right" && localStorage.getItem("currencyFormat")}
                          </span>
                        </span>
                      ) : (
                        <span className="nav-page-title">
                          {this.props.title}
                        </span>
                      )}
                    </React.Fragment>
                  ) : null}
                  {this.props.has_delivery_icon && (
                    <LightSpeed left>
                      <img
                        src="/assets/img/various/delivery-bike.png"
                        alt={this.props.title}
                        className="nav-page-title"
                      />
                    </LightSpeed>
                  )}
                </p>
                <div
                  className={`fixed-restaurant-info ${
                    !this.props.isSingleItemDescription ? "hidden" : ""
                  }`}
                  ref={(node) => {
                    this.heading = node;
                  }}
                >
                  <span className="font-w700 fixedRestaurantName">
                    {this.props.isSingleItemDescription
                      ? this.props.single_item.name
                      : this.props.restaurant.name
                      ? this.props.restaurant.name
                      : null}
                  </span>
                </div>
                <div className="input-group-append">
                  {!this.props.disable_search && (
                    <button
                      type="submit"
                      className="btn search-navs-btns"
                      style={{
                        position: "relative",
                        maxWidth: "40px",
                        padding: "4px",
                      }}
                    >
                      <i className="si si-magnifier" />
                      <Ink duration="500" />
                    </button>
                  )}
                  {/* {this.props.homeButton && (
                    <button
                      type="button"
                      className="btn search-navs-btns nav-home-btn"
                      style={{
                        position: "relative",
                        maxWidth: "40px",
                        padding: "4px",
                      }}
                      onClick={() => {
                        setTimeout(() => {
                          this.context.router.history.push("/");
                        }, 200);
                      }}
                    >
                      <i className="si si-home" />
                      <Ink duration="500" />
                    </button>
                  )} */}

                  <button
                    type="button"
                    className="btn search-navs-btns nav-home-btn"
                    style={{
                      position: "relative",
                      maxWidth: "40px",
                      padding: "4px",
                      fontSize: "1.5rem",
                    }}
                    onClick={() => {
                      setTimeout(() => {
                        this.context.router.history.push("/my-favorite-stores");
                      }, 200);
                    }}
                  >
                    <FontAwesomeIcon icon={faHeart} />
                    <Ink duration="500" />
                  </button>
                  {/* )} */}
                  {this.props.shareButton && (
                    <WebShare
                      link={url}
                      title={title}
                      cartItems={cartItems}
                      isCartProduct={this.props.isCartProduct}
                      restaurantSlug={this.props.restaurantSlug}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default BackWithSearch;
